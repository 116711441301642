import { SmartrrFilterType, SmartrrSortType } from "@smartrr/shared/utils/paginatedQuery";
import { useMemo, useState } from "react";

import { DEFAULT_PAGE, DEFAULT_SIZE } from "@vendor-app/constants/table";

export const useTableHandlers = (
  defaultOrderByField: string,
  defaultOrder: SmartrrSortType = "DESC",
  defaultPageNumber = DEFAULT_PAGE,
  defaultPageSize = DEFAULT_SIZE,
  defaultFilterByField?: string,
  defaultFilter?: SmartrrFilterType
) => {
  const [pageNumber, setPageNumber] = useState<number>(defaultPageNumber);
  const [orderByField, setOrderByField] = useState<string>(defaultOrderByField);
  const [orderByValue, setOrderByValue] = useState<SmartrrSortType>(defaultOrder);
  const [pageSize, setPageSize] = useState<number>(defaultPageSize);
  const [filter, setFilter] = useState<SmartrrFilterType>(defaultFilter ?? {});

  const tableProps = useMemo(
    () => ({
      pageNumber,
      orderByField,
      orderByValue,
      pageSize,
      filter,
    }),
    [pageNumber, orderByValue, orderByField, pageSize, filter]
  );
  const tableHandlers = useMemo(
    () => ({
      setPageNumber,
      setOrderByField,
      setOrderByValue,
      setPageSize,
      setFilter,
    }),
    [setPageNumber, setOrderByField, setOrderByValue, setPageSize, setFilter]
  );

  return [tableProps, tableHandlers] as [typeof tableProps, typeof tableHandlers];
};
